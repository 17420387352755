import * as React from "react";
import { useState, useEffect } from "react";
import styles from "./Grid.module.css";
import ProductCard from "./ProductCardAnimated";

const products = [
  {
    prodName: "Nike Dri-FIT Flex Woven Short",
    prodColor: "Team Scarlet",
    prodImg: "./img/Product1_sm.png",
    prodPrice: "$35",
  },
  {
    prodName: "Nike Legend Short Sleeve T-Shirt",
    prodColor: "Team Black",
    prodImg: "./img/Product2_sm.png",
    prodPrice: "$35",
  },
  {
    prodName: "Jordan Team Full Zip Jacket",
    prodColor: "Team Scarlet / White",
    prodImg: "./img/Product3_sm.png",
    prodPrice: "$70",
  },
  {
    prodName: "Nike Dry Showtime Full Zip Hoodie",
    prodColor: "Team Black",
    prodImg: "./img/Product4_sm.png",
    prodPrice: "$90",
  },
  {
    prodName: "Nike Campus Cap",
    prodColor: "Team Scarlet",
    prodImg: "./img/Product5_sm.png",
    prodPrice: "$30",
  },
  {
    prodName: "Nike Academy Team Backpack",
    prodColor: "Team Black",
    prodImg: "./img/Product10_sm.png",
    prodPrice: "$65",
  },
  {
    prodName: "Nike Epic Knit Pant 2.0",
    prodColor: "Team Scarlet",
    prodImg: "./img/Product6_sm.png",
    prodPrice: "$55",
  },
  {
    prodName: "Nike Legend Short Sleeve T-Shirt",
    prodColor: "Team Black",
    prodImg: "./img/Product8_sm.png",
    prodPrice: "$35",
  },
  {
    prodName: "Nike Legend Short Sleeve T-Shirt",
    prodColor: "Team Scarlet",
    prodImg: "./img/Product7_sm.png",
    prodPrice: "$35",
  },
  {
    prodName: "Jordan Team Pant",
    prodColor: "Team Black",
    prodImg: "./img/Product9_sm.png",
    prodPrice: "$65",
  },
];

export default function BackgroundGrid() {
  const [productGrid, setProductGrid] = useState([]);
  const numGridItems = 30;

  useEffect(() => {
    let images = [];
    for (let i = 0; i < numGridItems; i++) {
      // console.log("lopping" + i);
      images[i] = { productData: products[i % products.length], prodKey: i };
    }
    setProductGrid(images);
  }, []);

  // useEffect(() => {
  //   let ival = setInterval(() => {
  //     let indexPos = Math.floor(Math.random() * numGridItems);

  //     let newImages = productGrid.map((product) => {
  //       if (product.prodKey === indexPos) {
  //         console.log(product.prodKey);
  //         const newProduct = {
  //           ...product,
  //           productData: products[Math.floor(Math.random() * products.length)],
  //         };
  //         return newProduct;
  //       }
  //       return product;
  //     });
  //     setProductGrid(newImages);
  //   }, Math.random() * 1000);
  //   return () => {
  //     clearInterval(ival);
  //   };
  // });

  return (
    <ul className={styles["product-grid"]} key={"productlist"}>
      {productGrid.map((prod) => {
        return (
          <ProductCard productData={prod.productData} key={prod.prodKey} />
        );
      })}
    </ul>
  );
}
