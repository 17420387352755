import * as React from "react";
// import { useState } from "react";
import styles from "./ProductCard.module.css";
import { motion, AnimatePresence } from "framer-motion";

export default function ProductCard({ productData }) {
  const duration = 0.3;
  //const [data, updateData] = useState(productData);

  // updateData((productData) => ({
  //   ...productData,
  // }));
  // console.log("rerender" + productData.prodImg);
  return (
    <li className={styles["product-card"]}>
      <AnimatePresence initial={true}>
        <motion.div
          layout="position"
          // key={key}
          initial={{
            opacity: 0,
            scale: 0.5,
            // transition: {
            //   duration: duration,
            //   delay: Math.random(),
            //   ease: "easeOut",
            // },
          }}
          animate={{
            opacity: 1,
            scale: 1,
            transition: {
              duration: duration,
              delay: Math.random(),
              ease: "easeOut",
            },
          }}
          exit={{
            opacity: 0,
            scale: 0.5,
            transition: { duration: duration, ease: "easeIn" },
          }}
        >
          <div className={styles["product-image"]}>
            <img src={productData.prodImg} alt="Product Card" />
          </div>
          <div className={styles["product-detail"]}>
            <div className={styles["product-desc"]}>
              <span className={styles["product-name"]}>
                {productData.prodName}
              </span>
              <span className={styles["product-color"]}>
                {productData.prodColor}
              </span>
            </div>
            <div className={styles["product-price"]}>
              {productData.prodPrice}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </li>
  );
}
